import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";
import policies from "./PoliciesContent";
import "./MemberShipPlansCard.css"
import { trackEvent } from "../App";

function MemberShipPlansCard({ curLanguage, planCardContent, onHandlePrompt, isBlack }) {
	if (!planCardContent) return;

	return (
		<div
			className={
				isBlack === true ? "member-plans-box-div-black" : "member-plans-box-div"
			}
		>
			<div className="member-plan-title">
				<span>{planCardContent.subtitle}</span>
				<h4>{planCardContent.title}</h4>
				<div className="member-plans-box-price">${planCardContent.price}*</div>
			</div>

			<ul>
				{planCardContent.list.map((item) => (
					<li key={item.listItem}>
						{item.isIncluded ? (
							<IoCheckmarkSharp className="member-plan-icon" />
						) : (
							<IoCloseSharp className="member-plan-icon" />
						)}
						<span
							className={isBlack === true ? "plan-focus-black" : "plan-focus"}
						>
							{item.listItem}
						</span>
					</li>
				))}
				<li>
					<span>
						{curLanguage === "en" ? "*Monthly payment." : "*Pagamento Mensal."}
					</span>
				</li>
			</ul>
			<div className="subscribe-div">
				<button
					type="button"
					className="subscribe-button"
					onClick={() => {
						trackEvent(
							'subscribe_click',
							'Membership',
							`Plan: ${planCardContent.planName} - ${isBlack ? 'Black' : (planCardContent.planName.includes('One') ? 'One' : 'Gold')}`, 
							'membership_section'
						);

						onHandlePrompt(
							policies.membership,
							"subscription",
							planCardContent.planLink,
						);
					}}
				>
					{curLanguage === "en" ? "Subscribe" : "Assinar"}
				</button>
			</div>
		</div>
	);
}

export default MemberShipPlansCard;