import React from "react";
import "./GalleryImage.css";

function GalleryImage({ imgAddress, imgAlt, onClick }) {
  return (
    <div
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          onClick();
        }
      }}
      tabIndex="0"
      className="gallery-div-img"
    >
      <img className="gallery-img" src={imgAddress} alt={imgAlt} />
    </div>
  );
}

export default GalleryImage;