const membershipContent = {
  en: {
    content: [
      `At the Gentlemen's Club, we're excited to introduce our
    membership, unlocking limitless access to exceptional grooming
    services, expert craftsmanship, exclusive perks, and unbeatable
    savings. We take pride in being the trailblazers in
    Massachusetts, presenting you with this exceptional opportunity.`,
      `With our membership, you have the freedom to enjoy our services 
    as often as you'd like, conveniently covered by a monthly
    payment*. It's more than just grooming; it's a way of life.
    Explore our membership options below and join us today.`,
      `* Services included with the membership of your choice. `,
    ],
    advantagesItems: [
      "Enjoy exceptional services at the finest barbershop of Massachusetts",
      "Cheaper than paying per service",
      "You always look good!",
      "10% discount for products and services",
      "Exclusive monthly prizes/gifts",
      "Never have trouble shaving at home again",
      "Effortless automatic payment available",
    ],
    haircutPlan: {
      title: "Haircut",
      subtitle: "black plan",
      price: `99.90`,
      planLink: "https://buy.stripe.com/dR67vSeN04YN25icMO",
      list: [
        {
          listItem: "Unlimited Haircuts",
          isIncluded: true,
        },
        {
          listItem: "Hot Towel Shaves (not included)",
          isIncluded: false,
        },
        {
          listItem: "Monday to Saturday",
          isIncluded: true,
        },
        {
          listItem: "10% discount on products",
          isIncluded: true,
        },
        {
          listItem: "10% discount on services",
          isIncluded: true,
        },
        {
          listItem: "Better cost benefit",
          isIncluded: true,
        },
        {
          listItem: "Choose any barber",
          isIncluded: true,
        },
        {
          listItem: "Cancel any time",
          isIncluded: true,
        },
      ],
    },
    diamondPlan: {
      title: "Diamond",
      subtitle: "VIP Plan",
      price: `239.90`,
      // planLink: "https://buy.stripe.com/dR67vSeN04YN25icMO",
      list: [
        {
          listItem: "Unlimited Haircuts",
          isIncluded: true,
        },
        {
          listItem: "Unlimited Hot Towel Shaves",
          isIncluded: true,
        },
        {
          listItem: "Book up to 4 next appointments",
          isIncluded: true,
        },
        {
          listItem: "Monday to Saturday",
          isIncluded: true,
        },
        {
          listItem: "15% discount on products",
          isIncluded: true,
        },
        {
          listItem: "15% discount on services",
          isIncluded: true,
        },
        {
          listItem: "Better cost benefit",
          isIncluded: true,
        },
        {
          listItem: "Choose any barber",
          isIncluded: true,
        },
        {
          listItem: "Cancel any time",
          isIncluded: true,
        },
      ],
    },

    beardPlan: {
      title: "Beard",
      subtitle: "gold plan",
      price: `109.90`,
      planLink: "https://buy.stripe.com/28o3fC34iaj7cJW5kl",
      list: [
        {
          listItem: "Unlimited Haircuts (not included)",
          isIncluded: false,
        },
        {
          listItem: "Limited Hot Towel Shaves (4 services per month)",
          isIncluded: true,
        },
        {
          listItem: "Monday to Saturday",
          isIncluded: true,
        },
        {
          listItem: "10% discount on products",
          isIncluded: true,
        },
        {
          listItem: "10% discount on services",
          isIncluded: true,
        },
        {
          listItem: "Better cost benefit",
          isIncluded: true,
        },
        {
          listItem: "Choose any barber",
          isIncluded: true,
        },
        {
          listItem: "Cancel any time",
          isIncluded: true,
        },
      ],
    },
    haircutAndBeardPlan: {
      title: "Haircut & Beard",
      subtitle: "black plan",
      price: `179.90`,
      planLink: "https://buy.stripe.com/cN2dUg20e3UJ8tG7ss",
      list: [
        {
          listItem: "Unlimited Haircuts",
          isIncluded: true,
        },
        {
          listItem: "Unlimited Hot Towel Shaves",
          isIncluded: true,
        },
        {
          listItem: "Monday to Saturday",
          isIncluded: true,
        },
        {
          listItem: "10% discount on products",
          isIncluded: true,
        },
        {
          listItem: "10% discount on services",
          isIncluded: true,
        },
        {
          listItem: "Better cost benefit",
          isIncluded: true,
        },
        {
          listItem: "Choose any barber",
          isIncluded: true,
        },
        {
          listItem: "Cancel any time",
          isIncluded: true,
        },
      ],
    },
    haircutGoldPlan: {
      title: "Haircut",
      subtitle: "gold plan",
      price: `69.90`,
      planLink: "https://buy.stripe.com/6oEg2o6gu8aZ6lybIL",
      list: [
        {
          listItem: "Unlimited Haircuts",
          isIncluded: true,
        },
        {
          listItem: "Hot Towel Shaves (not included)",
          isIncluded: false,
        },
        {
          listItem: "(Only) Monday to Wednesday",
          isIncluded: true,
        },
        {
          listItem: "10% discount on products",
          isIncluded: true,
        },
        {
          listItem: "10% discount on services",
          isIncluded: true,
        },
        {
          listItem: "Better cost benefit",
          isIncluded: true,
        },
        {
          listItem: "Choose any barber",
          isIncluded: true,
        },
        {
          listItem: "Cancel any time",
          isIncluded: true,
        },
      ],
    },
    haircutAndBeardGoldPlan: {
      title: "Haircut & Beard",
      subtitle: "gold plan",
      price: `129.90`,
      planLink: "https://buy.stripe.com/4gwdUg20e62R7pC9AE",
      list: [
        {
          listItem: "Unlimited Haircuts",
          isIncluded: true,
        },
        {
          listItem: "Limited Hot Towel Shaves (4 services per month)",
          isIncluded: true,
        },
        {
          listItem: "(Only) Monday to Wednesday",
          isIncluded: true,
        },
        {
          listItem: "10% discount on products",
          isIncluded: true,
        },
        {
          listItem: "10% discount on services",
          isIncluded: true,
        },
        {
          listItem: "Better cost benefit",
          isIncluded: true,
        },
        {
          listItem: "Choose any barber",
          isIncluded: true,
        },
        {
          listItem: "Cancel any time",
          isIncluded: true,
        },
      ],
    },
    haircutOnePlan: {
      title: "Haircut",
      subtitle: "one plan",
      price: `40.00`,
      planLink: "https://buy.stripe.com/00geYk7ky0Ix4dq7sx",
      list: [
        {
          listItem: "Limited to one haircut",
          isIncluded: true,
        },
        {
          listItem: "Second haircut with 10% discount",
          isIncluded: true,
        },
        {
          listItem: "Monday to Saturday",
          isIncluded: true,
        },
        // {
        //   listItem: "10% discount on products",
        //   isIncluded: true,
        // },
        // {
        //   listItem: "10% discount on services",
        //   isIncluded: true,
        // },
        {
          listItem: "Better cost benefit",
          isIncluded: true,
        },
        {
          listItem: "Choose any barber",
          isIncluded: true,
        },
        {
          listItem: "Cancel any time",
          isIncluded: true,
        },
      ],
    },
  },
  pt: {
    content: [
      `No Gentlemen's Club, estamos empolgados em apresentar nosso
        membership com acesso ilimitado a serviços da barbearia, benefícios exclusivos e
        economia imbatível. Temos orgulho de sermos pioneiros em
        Massachusetts, oferecendo a você esta oportunidade excepcional.`,
      // `É uma oportunidade única para mantê-lo com uma aparência impecável o
      //   tempo todo, dentro do seu orçamento. Você vai apreciar a satisfação
      //   incomparável de se sentir sempre no seu melhor estilo.`,
      `Com nosso membership, você tem a liberdade de desfrutar de nossos serviços
        quantas vezes desejar*. É mais do que apenas cuidados pessoais; é um estilo de vida.
        Explore nossas opções de assinatura abaixo e junte-se a nós hoje.`,
      `* Serviços inclusos na sua escolha de cobertura.`,
    ],
    advantagesItems: [
      "Desfrute de serviços excepcionais no melhor barbearia de Massachusetts",
      "Mais econômico do que pagar por serviço individualmente",
      "Você sempre estará com uma aparência impecável!",
      "Desconto de 10% em produtos e serviços",
      "Prêmios/gifts exclusivos mensais",
      "Nunca mais se preocupe em fazer a barba em casa novamente",
      "Pagamentos automáticos recorrentes sem esforço",
    ],
    haircutPlan: {
      title: "Haircut",
      subtitle: "Plano Black",
      price: `99.90`,
      planLink: "https://buy.stripe.com/dR67vSeN04YN25icMO",
      list: [
        {
          listItem: "Cortes de Cabelo Ilimitados",
          isIncluded: true,
        },
        {
          listItem: "Barba com Toalha Quente (não incluso)",
          isIncluded: false,
        },
        {
          listItem: "Segunda a Sábado",
          isIncluded: true,
        },
        {
          listItem: "10% de desconto em produtos",
          isIncluded: true,
        },
        {
          listItem: "10% de desconto em serviços",
          isIncluded: true,
        },
        {
          listItem: "Melhor custo-benefício",
          isIncluded: true,
        },
        {
          listItem: "Corte com qualquer barbeiro",
          isIncluded: true,
        },
        {
          listItem: "Sem fidelidade",
          isIncluded: true,
        },
      ],
    },
    diamondPlan: {
      title: "Diamond",
      subtitle: "plano",
      price: `$239.90`,
      // planLink: "https://buy.stripe.com/dR67vSeN04YN25icMO",
      list: [
        {
          listItem: "Cortes de Cabelo Ilimitados",
          isIncluded: true,
        },
        {
          listItem: "Barba com Toalha Quente Ilimitado",
          isIncluded: true,
        },
        {
          listItem: "Pode marcar os próximos quatro agendamentos",
          isIncluded: true,
        },
        {
          listItem: "Segunda a Sábado",
          isIncluded: true,
        },
        {
          listItem: "15% de desconto em produtos",
          isIncluded: true,
        },
        {
          listItem: "15% de desconto em serviços",
          isIncluded: true,
        },
        {
          listItem: "Corte com qualquer barbeiro",
          isIncluded: true,
        },
        {
          listItem: "Sem fidelidade",
          isIncluded: true,
        },
      ],
    },
    beardPlan: {
      title: "Beard",
      subtitle: "Plano Gold",
      price: `109.90`,
      planLink: "https://buy.stripe.com/28o3fC34iaj7cJW5kl",
      list: [
        {
          listItem: "Cortes de Cabelo (não incluso)",
          isIncluded: false,
        },
        {
          listItem: "Barba com Toalha Quente Limitado (4 barbas por mês)",
          isIncluded: true,
        },
        {
          listItem: "Segunda a Sábado",
          isIncluded: true,
        },
        {
          listItem: "10% de desconto em produtos",
          isIncluded: true,
        },
        {
          listItem: "10% de desconto em serviços",
          isIncluded: true,
        },
        {
          listItem: "Melhor custo-benefício",
          isIncluded: true,
        },
        {
          listItem: "Corte com qualquer barbeiro",
          isIncluded: true,
        },
        {
          listItem: "Sem fidelidade",
          isIncluded: true,
        },
      ],
    },
    haircutAndBeardPlan: {
      title: "Haircut & Beard",
      subtitle: "Plano Black",
      price: `179.90`,
      planLink: "https://buy.stripe.com/cN2dUg20e3UJ8tG7ss",
      list: [
        {
          listItem: "Cortes de Cabelo Ilimitados",
          isIncluded: true,
        },
        {
          listItem: "Barba com Toalha Quente Ilimitadas",
          isIncluded: true,
        },
        {
          listItem: "Segunda a Sábado",
          isIncluded: true,
        },
        {
          listItem: "10% de desconto em produtos",
          isIncluded: true,
        },
        {
          listItem: "10% de desconto em serviços",
          isIncluded: true,
        },
        {
          listItem: "Melhor custo-benefício",
          isIncluded: true,
        },
        {
          listItem: "Corte com qualquer barbeiro",
          isIncluded: true,
        },
        {
          listItem: "Sem fidelidade",
          isIncluded: true,
        },
      ],
    },
    haircutGoldPlan: {
      title: "Haircut",
      subtitle: "plano gold",
      price: `69.90`,
      planLink: "https://buy.stripe.com/6oEg2o6gu8aZ6lybIL",
      list: [
        {
          listItem: "Cortes de Cabelo Ilimitados",
          isIncluded: true,
        },
        {
          listItem: "Barba com Toalha Quente (não incluso)",
          isIncluded: false,
        },
        {
          listItem: "Apenas de Segunda a Quarta",
          isIncluded: true,
        },
        {
          listItem: "10% de desconto em produtos",
          isIncluded: true,
        },
        {
          listItem: "10% de desconto em serviços",
          isIncluded: true,
        },
        {
          listItem: "Melhor custo-benefício",
          isIncluded: true,
        },
        {
          listItem: "Corte com qualquer barbeiro",
          isIncluded: true,
        },
        {
          listItem: "Sem fidelidade",
          isIncluded: true,
        },
      ],
    },
    haircutAndBeardGoldPlan: {
      title: "Haircut & Beard",
      subtitle: "plano gold",
      price: `129.90`,
      planLink: "https://buy.stripe.com/4gwdUg20e62R7pC9AE",
      list: [
        {
          listItem: "Cortes de Cabelo Ilimitados",
          isIncluded: true,
        },
        {
          listItem: "Barba com Toalha Quente Limitado (4 barbas por mês)",
          isIncluded: true,
        },
        {
          listItem: "Apenas de Segunda a Quarta",
          isIncluded: true,
        },
        {
          listItem: "10% de desconto em produtos",
          isIncluded: true,
        },
        {
          listItem: "10% de desconto em serviços",
          isIncluded: true,
        },
        {
          listItem: "Melhor custo-benefício",
          isIncluded: true,
        },
        {
          listItem: "Corte com qualquer barbeiro",
          isIncluded: true,
        },
        {
          listItem: "Sem fidelidade",
          isIncluded: true,
        },
      ],
    },
    haircutOnePlan: {
      title: "Haircut",
      subtitle: "plano one",
      price: `40.00`,
      planLink: "https://buy.stripe.com/00geYk7ky0Ix4dq7sx",
      list: [
        {
          listItem: "Limitado a um corte mensal",
          isIncluded: true,
        },
        {
          listItem: "10% de desconto no segundo corte",
          isIncluded: true,
        },
        {
          listItem: "Segunda a Sábado",
          isIncluded: true,
        },
        {
          listItem: "Melhor custo-benefício",
          isIncluded: true,
        },
        {
          listItem: "Corte com qualquer barbeiro",
          isIncluded: true,
        },
        {
          listItem: "Sem fidelidade",
          isIncluded: true,
        },
      ],
    },
  },
};

export default membershipContent;
