import React, { useState, useEffect } from "react";
import "./App.css";
import Header from "./Components/Header";
import Hero from "./Components/Hero";
import About from "./Components/About";
import Services from "./Components/Services";
import Membership from "./Components/Membership";
import CTA from "./Components/CTA";
import GroomsDay from "./Components/GroomsDay";
import Gallery from "./Components/Gallery";
import Team from "./Components/Team";
import Footer from "./Components/Footer";
import PromptWindow from "./Components/PromptWindow";
import FloatingBook from "./Components/FloatingBook";

export const trackEvent = (eventName, category, label, location = null) => {
  if (window.gtag) {
    const eventParams = {
      'event_category': category,
      'event_label': label
    };

    if (location) {
      eventParams.button_location = location;
    }

    window.gtag('event', eventName, eventParams);
  }
};


function App() {
  const [isSticky, setIsSticky] = useState(false);
  const [promptOpen, setPromptOpen] = useState(false);
  const [promptContent, setPromptContent] = useState(null);
  const [language, setLanguage] = useState("en");
  
  function handlePrompt(content, frame, planLink) {
    setPromptOpen(!promptOpen);
    setPromptContent({ content, frame, planLink });
  }

  function handlePromptClose() {
    setPromptOpen(false);
    setPromptContent(null);
  }

  function handleLanguage(lang) {
    setLanguage(lang);
  }

  useEffect(() => {
    const handleScroll = () => {
      // Determine the scroll position and the position of the hero section
      const scrollPosition = window.scrollY;
      const headerSectionHeight = document.getElementById("hero").offsetHeight;

      // Update the state to make the navigation sticky
      setIsSticky(scrollPosition >= headerSectionHeight - 80);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Header
        curLanguage={language}
        onSticky={isSticky}
        onHandleLanguage={handleLanguage}
      />
      <Hero curLanguage={language} />
      <About curLanguage={language} />
      <Services curLanguage={language} onHandlePrompt={handlePrompt} />
      <Membership curLanguage={language} onHandlePrompt={handlePrompt} />
      <Team curLanguage={language} />
      <GroomsDay curLanguage={language} />
      <Gallery curLanguage={language} />
      <CTA curLanguage={language} />
      <Footer
        onHandlePrompt={handlePrompt}
        onHandleLanguage={handleLanguage}
        curLanguage={language}
      />
      <PromptWindow
        curLanguage={language}
        onPromptOpen={promptOpen}
        onPromptClose={handlePromptClose}
        onPromptContent={promptContent}
      />
      <FloatingBook curLanguage={language} className="floating-book"/>
    </>
  );
}

export default App;
