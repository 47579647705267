import React, { useState, useEffect } from "react";
import "./FloatingBook.css";
import { trackEvent } from '../App'

function FloatingBook({ curLanguage }) {
  const [isHeroSection, setIsHeroSection] = useState(true);
  const [isInCTASection, setIsInCTASection] = useState(false);

  useEffect(() => {
    // Após 1 segundo, sai do centro e vai pro canto
    const timer = setTimeout(() => {
      setIsHeroSection(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const ctaSection = document.getElementById("cta");
    if (!ctaSection) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInCTASection(entry.isIntersecting);
      },
      {
        threshold: 0.3, // Quando 30% da seção estiver visível
      }
    );

    observer.observe(ctaSection);

    return () => {
      if (ctaSection) observer.unobserve(ctaSection);
    };
  }, []);

  return (
    <a
      href="#cta"
      onClick={() => trackEvent('book_click', 'Booking', 'Floating Book Button', 'floating')}
      className={`floating-book-button 
        ${isHeroSection ? "center-hero" : "fade-in"} 
        ${isInCTASection ? "fade-out" : ""}
      `}
    >
      <p className="floating-book-text">
        {curLanguage === "en" ? "BOOK" : "AGENDAR"}
      </p>
    </a>
  );
}

export default FloatingBook;