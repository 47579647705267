import "./CTA.css";
import {
  FaWhatsappSquare,
  FaPhoneSquare,
  FaExternalLinkSquareAlt,
  FaLocationArrow,
} from "react-icons/fa";
import { trackEvent } from "../App";

export default function CTA({ curLanguage }) {
  return (
    <div className="cta" id="cta">
      <div className="component cta-box">
        <h2 className="components-header-h2">
          {curLanguage === "en" ? "Booking Options" : "Como Agendar"}
        </h2>
        <div className="cta-box-grid">
          <CTACard
            cardTitle="Gentlemen's App"
            cardText={
              curLanguage === "en"
                ? `iOS app platform (recommended)`
                : `Aplicativo iOS ("recomendado")`
            }
            cardLink="https://apps.apple.com/us/app/gentlemens-image-style/id6743096354"
            isFirst={true}
          >
            <div>
              <FaExternalLinkSquareAlt className="cta-card-icon" />
            </div>
          </CTACard>
          <CTACard
            cardTitle="WhatsApp"
            cardText="(857) 253-1766"
            cardLink="https://wa.me/message/S6C4E5WQQNUBD1"
          >
            <div>
              <FaWhatsappSquare className="cta-card-icon" />
            </div>
          </CTACard>
          <CTACard
            cardTitle={curLanguage === "en" ? "Phone" : "Fone"}
            cardText="(857) 253-1766"
            cardLink="tel:857-253-1766"
          >
            <div>
              <FaPhoneSquare className="cta-card-icon" />
            </div>
          </CTACard>
          <CTACard
            cardTitle={curLanguage === "en" ? "Walk-in" : "Pessoalmente"}
            cardText="157 Main St, Stoneham, MA"
            cardLink="https://www.google.com/maps/place/Gentlemen's+Club+Barbershop/@42.4920394,-71.1008219,17z/data=!3m1!4b1!4m6!3m5!1s0x89e375794459cb47:0xc22604cec72aa25!8m2!3d42.4920394!4d-71.1008219!16s%2Fg%2F11kqfqzfq8?entry=ttu"
          >
            <div>
              <FaLocationArrow className="cta-card-icon" />
            </div>
          </CTACard>
        </div>
        <p className="cta-card-note">
          {curLanguage === "en"
            ? `* Recommended: the most easy to navigate versatile platform where you  create a simple profile, see all services, schedule, reviews and more.`
            : `Recomendado: a plataforma mais versátil e fácil de navegar, onde você pode criar um perfil simples, ver todos os serviços, agendar, ler avaliações e muito mais.`}
        </p>
        <p className="cta-card-schedule">
          {curLanguage === "en"
            ? `Monday to Thursday - 10:00 am to 12:00 pm / 1:00 pm to 7:00 pm`
            : `Segunda a Quinta - 10:00 am to 12:00 pm / 1:00 pm to 7:00 pm`}
        </p>
        <p className="cta-card-schedule">
          {curLanguage === "en"
            ? `Friday - 9:00 am to 12:00 pm / 1:00 pm to 7:00 pm`
            : `Sexta - 9:00 am to 12:00 pm / 1:00 pm to 7:00 pm`}
        </p>
        <p className="cta-card-schedule">
          {curLanguage === "en"
            ? `Saturday - 9:00 am to 12:20 pm / 1:00 pm to 6:00 pm`
            : `Sábado - 9:00 am to 12:20 pm / 1:00 pm to 6:00 pm`}
        </p>
        <p className="cta-card-schedule">
          {curLanguage === "en" ? `Sunday - Closed` : `Domingo - Fechado`}
        </p>
      </div>
    </div>
  );
}


function CTACard({ cardTitle, cardText, cardLink, children, isFirst }) {
  const handleClick = () => {
    trackEvent(`cta-click`, "Engagement", cardTitle, "booking-options-section");
  };

  return (
    <div className="grid-test">
      <a
        className={`cta-card ${isFirst ? 'first-card' : ''}`}
        href={cardLink}
        target="_blank"
        rel="noreferrer"
        onClick={handleClick}
      >
        {children}
        <span className="cta-card-title">{cardTitle}</span>
        <p className="cta-card-text">{cardText}</p>
      </a>
    </div>
  );
}