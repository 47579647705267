import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import "./Hero.css";

export default function Hero({ curLanguage }) {
  const [curImgIndex, setCurImgIndex] = useState(0);
  const [showHeroLogo, setShowHeroLogo] = useState(true);
  const heroBackground = document.getElementById("hero-background");
  const imagesUrl = [
    "../../../hero-bg-1.jpeg",
    "../../../hero-bg-2.jpeg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurImgIndex((prevIndex) => (prevIndex + 1) % imagesUrl.length);
    }, 2500);

    return () => clearInterval(interval);
  }, [imagesUrl.length]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowHeroLogo(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const resetBgAnimation = () => {
    if (heroBackground) {
      heroBackground.classList.remove("back-test");
      void heroBackground.offsetWidth;
      heroBackground.classList.add("back-test");
    }
  };


  const handlePrev = () => {
    setCurImgIndex((prevIndex) =>
      prevIndex === 0 ? imagesUrl.length - 1 : prevIndex - 1
    );
    resetBgAnimation();
  };

  const handleNext = () => {
    setCurImgIndex((prevIndex) => (prevIndex + 1) % imagesUrl.length);
    resetBgAnimation();
  };

  return (
    <div className="hero" id="hero">
      <DivTest testUrl={imagesUrl[curImgIndex]} />

      {/* Setas */}
      <button className="hero-arrow left" onClick={handlePrev} type="button">
        <ChevronLeft size={40} />
      </button>
      <button className="hero-arrow right" onClick={handleNext} type="button">
        <ChevronRight size={40} />
      </button>

      <div className="hero-logo-div">
        <img
          className={`hero-logo ${showHeroLogo ? "fade-in" : "fade-out"}`}
          src="component-4.webp"
          alt="Company Logo"
        />
      </div>
    </div>
  );
}

function DivTest({ testUrl }) {
  const styleA = {
    backgroundImage: `linear-gradient(
      to right,
      rgba(255, 255, 255, 0.1),
      rgba(0, 0, 0, 0.25)
    ), url(${testUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    transition: "transform 1.5s ease, filter 1.5s ease",
    filter: "brightness(1.05) saturate(1.1)",
  };

  return <div id="hero-background" className="back-test" style={styleA} />;
}