import "./Membership.css";
import membershipContent from "./MembershipContent";
import MemberShipPlansCard from "./MemberShipPlansCard";
import DiamondPlan from "./DiamondPlan";
import { RxComponent1 } from "react-icons/rx";
import { RiDoubleQuotesL } from "react-icons/ri";

function Membership({ curLanguage, onHandlePrompt }) {
	return (
		<section className="component section-membership" id="membership">
			<div className="membership-content">
				<h2 className="components-header-h2">
					{curLanguage === "en" ? "Membership" : "Assinatura"}
				</h2>

				<div className="member-grid">
					<div className="member-grid-div">
						<div className="member-intro-div">
							<RiDoubleQuotesL className="member-intro-div-icon" />
							<h3 className="member-intro-div-h3">
								{curLanguage === "en" ? "The Club" : "Conheça o Clube"}
							</h3>
							{membershipContent[`${curLanguage}`].content.map((item) => (
								<p key={item} className="member-intro-div-p">
									{item}
								</p>
							))}
						</div>

						<div className="member-grid-advantages">
							<h3 className="member-grid-advantages-h3">
								{" "}
								{curLanguage === "en" ? "Advantages" : "Vantagens"}
							</h3>
							<ul className="adv-test">
								{membershipContent[`${curLanguage}`].advantagesItems.map(
									(item) => (
										<li key={item}>
											<div>
												<RxComponent1 className="member-grid-div-icon" />
											</div>
											<span className="member-grid-div-span">{item}</span>
										</li>
									),
								)}
							</ul>
						</div>
					</div>

					<DiamondPlan
						curLanguage={curLanguage}
						onHandlePrompt={onHandlePrompt}
						planCardContent={membershipContent[`${curLanguage}`].diamondPlan}
					/>

					<h3 className="member-plans-h3-mob">
						{curLanguage === "en"
							? "Other Plans"
							: "Outros Planos"}
					</h3>

					<div className="member-plans-box">
						<MemberShipPlansCard
							curLanguage={curLanguage}
							onHandlePrompt={onHandlePrompt}
							planCardContent={
								membershipContent[`${curLanguage}`].haircutAndBeardGoldPlan
							}
						/>
						<MemberShipPlansCard
							curLanguage={curLanguage}
							onHandlePrompt={onHandlePrompt}
							planCardContent={membershipContent[`${curLanguage}`].beardPlan}
						/>
						<MemberShipPlansCard
							curLanguage={curLanguage}
							onHandlePrompt={onHandlePrompt}
							planCardContent={
								membershipContent[`${curLanguage}`].haircutGoldPlan
							}
						/>

						<MemberShipPlansCard
							isBlack={true}
							curLanguage={curLanguage}
							onHandlePrompt={onHandlePrompt}
							planCardContent={
								membershipContent[`${curLanguage}`].haircutAndBeardPlan
							}
						/>
						<MemberShipPlansCard
							isBlack={true}
							curLanguage={curLanguage}
							onHandlePrompt={onHandlePrompt}
							planCardContent={membershipContent[`${curLanguage}`].haircutPlan}
						/>
						<MemberShipPlansCard
							isBlack={false}
							curLanguage={curLanguage}
							onHandlePrompt={onHandlePrompt}
							planCardContent={
								membershipContent[`${curLanguage}`].haircutOnePlan
							}
						/>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Membership;
