import "./Footer.css";
import policies from "./PoliciesContent";
import {
  FaInstagramSquare,
  FaFacebookSquare,
  FaWhatsappSquare,
} from "react-icons/fa";
import { trackEvent } from "../App";

function Footer({ onHandlePrompt, onHandleLanguage, curLanguage }) {
  const curYear = new Date().getFullYear();

  const handleTrackEvent = (eventName, category, label) => {
    trackEvent(eventName, category, label, "Footer");
  };

  return (
    <div className="footer">
      <div className="grid--footer">
        <div className="logo-col">
          <div>
            <a href="#hero" className="footer-logo">
              <img
                className="footer-logo-img"
                src="component-9.webp"
                alt="Company Logo"
              />
            </a>
            <ul className="social-links">
              <li>
                <a
                  className="footer-link"
                  href="https://instagram.com/gentlemensclub.barbershop?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleTrackEvent('click', 'Social Media', 'Instagram')}
                >
                  <FaInstagramSquare className="social-icon" />
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  href="https://www.facebook.com/Gentlemensclubma?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleTrackEvent('click', 'Social Media', 'Facebook')}
                >
                  <FaFacebookSquare className="social-icon" />
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  href="https://wa.me/message/S6C4E5WQQNUBD1"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleTrackEvent('click', 'Social Media', 'Whatsapp')}
                >
                  <FaWhatsappSquare className="social-icon" />
                </a>
              </li>
            </ul>
          </div>
          <p className="copyright">
            Copyright &copy;{curYear} by Gentlemen's Club, Inc. All rights reserved.
          </p>
        </div>
        <div className="address-col">
          <p className="footer-heading">
            {curLanguage === "en" ? `Contact us` : `Contato`}
          </p>
          <ul className="footer-nav">
            <li>
              <a
                className="footer-link"
                href="https://www.google.com/maps/place/Gentlemen's+Club+Barbershop/@42.4920394,-71.1008219,17z/data=!3m1!4b1!4m6!3m5!1s0x89e375794459cb47:0xc22604cec72aa25!8m2!3d42.4920394!4d-71.1008219!16s%2Fg%2F11kqfqzfq8?entry=ttu"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleTrackEvent('click', 'Contact', 'Address')}
              >
                157 Main St, Stoneham, MA
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                href="tel:857-253-1766"
                onClick={() => handleTrackEvent('click', 'Contact', 'Phone')}
              >
                (857) 253-1766
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                href="mailto:bsgentlemensclub@hotmail.com"
                onClick={() => handleTrackEvent('click', 'Contact', 'Email')}
              >
                bsgentlemensclub@hotmail.com
              </a>
            </li>
          </ul>
        </div>
        <nav className="nav-col">
          <p className="footer-heading">The Gentlemen's App</p>
          <ul className="footer-nav">
            {/* <li>
              <a
                className="footer-link"
                href="/"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleTrackEvent('click', "The Gentlemen's App", 'Website')}
              >
                Website
              </a>
            </li> */}
            <li>
              <a
                className="footer-link"
                href="https://apps.apple.com/us/app/gentlemens-image-style/id6743096354"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleTrackEvent('click', "The Gentlemen's App", 'iOS App')}
              >
                iOS app
              </a>
            </li>
            {/* <li>
              <a
                className="footer-link"
                href="https://play.google.com/store/apps/details?id=net.booksy.customer&pli=1"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleTrackEvent('click', 'Booksy', 'Android App')}
              >
                Android app
              </a>
            </li> */}
          </ul>
        </nav>
        <nav className="nav-col">
          <p className="footer-heading">
            {curLanguage === "en" ? "Company" : "A Empresa"}
          </p>
          <ul className="footer-nav">
            <li>
              <span
                className="footer-prompt-btn"
                onClick={() => {
                  onHandlePrompt(policies.info, "policy");
                  handleTrackEvent('click', 'Company', 'Info');
                }}
              >
                {curLanguage === "en" ? "Info" : "Quem Somos"}
              </span>
            </li>
            <li>
              <span
                className="footer-prompt-btn"
                onClick={() => {
                  onHandlePrompt(policies.careers, "policy");
                  handleTrackEvent('click', 'Company', 'Careers');
                }}
              >
                {curLanguage === "en" ? "Careers " : "Carreiras"}
              </span>
            </li>
            <li>
              <span
                className="footer-prompt-btn"
                onClick={() => {
                  onHandlePrompt(policies.partnership, "policy");
                  handleTrackEvent('click', 'Company', 'Partnership');
                }}
              >
                {curLanguage === "en" ? "Partnership" : "Parcerias"}
              </span>
            </li>
          </ul>
        </nav>
        <nav className="nav-col">
          <p className="footer-heading">
            {curLanguage === "en" ? "Policies" : "Transparência"}
          </p>
          <ul className="footer-nav">
            <li>
              <span
                className="footer-prompt-btn"
                onClick={() => {
                  onHandlePrompt(policies.company, "policy");
                  handleTrackEvent('click', 'Policies', 'Company Policy');
                }}
              >
                {curLanguage === "en" ? "Company policy" : "Termos"}
              </span>
            </li>
            <li>
              <span
                className="footer-prompt-btn"
                onClick={() => {
                  onHandlePrompt(policies.membership, "policy");
                  handleTrackEvent('click', 'Policies', 'Membership Policy');
                }}
              >
                {curLanguage === "en" ? "Membership policy" : "Membership"}
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Footer;