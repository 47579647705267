import "./DiamondPlan.css";

function DiamondPlan({ curLanguage, planCardContent, onHandlePrompt }) {
  return (
    <div className="diamond-section" id="vip">
      <div className="component diamond-box">
        <h2 className="components-header-h2">
          {curLanguage === "en" ? "VIP Diamond Plan" : "Plano VIP Diamond"}
        </h2>
        <div className="diamond-box-content">
          <div className="diamond-card">
            <div className="diamond-content-grid">
              <div className="diamond-text-column">
                <h3 className="diamond-subtitle">
                  {curLanguage === "en" ? "Exclusive Membership" : "Associação Exclusiva"}
                </h3>
                <p className="diamond-intro">
                  {curLanguage === "en"
                    ? "We are thrilled to introduce our premium Diamond Plan. Experience a VIP journey with unparalleled benefits designed to elevate your personal care:"
                    : "Estamos empolgados em apresentar nosso Plano Diamond Premium. Experimente uma jornada VIP com benefícios incomparáveis projetados para elevar seu cuidado pessoal:"}
                </p>
              </div>
              <div className="diamond-perks-column">
                <h3 className="diamond-subtitle">
                  {curLanguage === "en" ? "Exclusive Benefits" : "Benefícios Exclusivos"}
                </h3>
                <div className="diamond-perks">
                  {[
                    curLanguage === "en" 
                      ? "Unlimited haircuts and hot towel shaves" 
                      : "Cortes de cabelo e barbas com toalha quente ilimitados",
                    curLanguage === "en" 
                      ? "15% discount on all products and additional services" 
                      : "15% de desconto em todos os produtos e serviços adicionais",
                    curLanguage === "en" 
                      ? "Priority booking up to 30 days in advance" 
                      : "Agendamento prioritário com até 30 dias de antecedência"
                  ].map((perk, index) => (
                    <div key={index} className="diamond-perk">
                      <span className="diamond-line"></span>
                      {perk}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <p className="diamond-cta">
              {curLanguage === "en"
                ? "Limited spots available! Contact us to secure your place on the waiting list."
                : "Vagas limitadas! Entre em contato para garantir seu lugar na lista de espera."}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiamondPlan;