import React, { useState } from "react";
import "./Gallery.css";
import GalleryImage from "./GalleryImage";
import { ChevronLeft, ChevronRight } from "lucide-react";

const images = [
  { src: "../../gallery-album/gallery-1.webp", alt: "Barbershop and customers" },
  { src: "../../gallery-album/gallery-2.webp", alt: "Staff training" },
  { src: "../../gallery-album/gallery-3.webp", alt: "Barber shaving customer" },
  { src: "../../gallery-album/gallery-4.webp", alt: "Barber shaving customer" },
  { src: "../../gallery-album/gallery-5.webp", alt: "Example of kid's service" },
  { src: "../../gallery-album/gallery-6.webp", alt: "Barber shaving customer" },
  { src: "../../gallery-album/gallery-7.webp", alt: "Barber shaving customer" },
  { src: "../../gallery-album/gallery-8.webp", alt: "Barbershop space" },
  { src: "../../gallery-album/gallery-9.webp", alt: "Barber shaving customer" },
  { src: "../../gallery-album/gallery-10.webp", alt: "Barber shaving customer" },
  { src: "../../gallery-album/gallery-11.webp", alt: "Example of kid's service" },
  { src: "../../gallery-album/gallery-12.webp", alt: "Barber shaving customer" },
];

function Gallery({ curLanguage }) {
  const [zoomed, setZoomed] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const imageZoom = (index) => {
    setCurrentImageIndex(index);
    setZoomed(true);
  };

  const closeZoom = () => {
    setZoomed(false);
  };

  const showPrevImage = (e) => {
    e.stopPropagation();
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const showNextImage = (e) => {
    e.stopPropagation();
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <div className="component gallery" id="gallery">
      <h2 className="components-header-h2">
        {curLanguage === "en" ? "Gallery" : "Galeria de Fotos"}
      </h2>
      <div className="gallery-box">
        <div className="gallery-div-grid">
          {images.map((image, index) => (
            <GalleryImage
              key={index}
              imgAddress={image.src}
              imgAlt={image.alt}
              onClick={() => imageZoom(index)}
            />
          ))}
        </div>
      </div>

      {zoomed && (
        <dialog
          className="overlay"
          open
          onClick={closeZoom}
          onKeyDown={(e) => {
            if (e.key === "Escape") closeZoom();
          }}
        >
          <ChevronLeft
            className={`chevron chevron-left ${currentImageIndex === 0 ? 'disabled' : ''}`}
            onClick={showPrevImage}
          />
          <img
            src={images[currentImageIndex].src}
            alt={images[currentImageIndex].alt}
            className="zoomed-image"
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") e.stopPropagation();
            }}
          />
          <ChevronRight
            className={`chevron chevron-right ${currentImageIndex === images.length - 1 ? 'disabled' : ''}`}
            onClick={showNextImage}
          />
        </dialog>
      )}
    </div>
  );
}

export default Gallery;