import "./Header.css";
import React, { useState } from "react";
import { HiBars3, HiOutlineXMark } from "react-icons/hi2";
import { MdOutlineLanguage } from "react-icons/md";
import { trackEvent } from '../App';

export default function Nav({ curLanguage, onSticky, onHandleLanguage }) {
  const [isOpen, setIsOpen] = useState(false);
  function handleIsOpen() {
    setIsOpen(false);
  }
  return (
    <header className={`header ${onSticky ? "header-dark" : ""}`}>
      <div className="nav-logo-div">
        <a href="#hero">
          <img
            className="nav-logo-large"
            // src="header-logo.webp"
            src="component-11.webp"
            alt="Company Logo"
          ></img>
          <img
            className="nav-logo-small"
            src="logo-noBackground.webp"
            alt="Company Logo"
          ></img>
        </a>
      </div>
      <div
        className={`nav-items-div ${!isOpen ? "nav-mobile nav-mobile--hidden" : "nav-mobile"
          }`}
      >
        <NavItems
          curLanguage={curLanguage}
          onIsOpen={handleIsOpen}
          onHandleLanguage={onHandleLanguage}
        />
      </div>
      <div className="nav-mobile-div">
        <button className="nav-mobile-btn" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <HiOutlineXMark className="nav-mobile-btn-icon" />
          ) : (
            <HiBars3 className="nav-mobile-btn-icon" />
          )}
        </button>
      </div>
    </header>
  );
}

function NavItems({ curLanguage, onIsOpen, onHandleLanguage }) {
  return (
    <ul>
      <li>
        <a href="#about" onClick={onIsOpen}>
          {curLanguage === "en" ? "About" : "A Barbearia"}
        </a>
      </li>
      <li>
        <a href="#services" onClick={onIsOpen}>
          {curLanguage === "en" ? "Services" : "Serviços"}
        </a>
      </li>
      <li>
        <a href="#membership" onClick={onIsOpen}>
          {curLanguage === "en" ? "Membership" : "Clube"}
        </a>
      </li>
      <li>
        <a href="#groomsDay" onClick={onIsOpen}>
          Groom's Day
        </a>
      </li>
      <li>
        <a href="#gallery" onClick={onIsOpen}>
          {curLanguage === "en" ? "Gallery" : "Galeria"}
        </a>
      </li>
      <li className="language-li">
        <label className="header-form-label">
          <MdOutlineLanguage className="header-form-icon" />
          <select
            className="header-form-select"
            type="checkbox"
            onChange={(e) => onHandleLanguage(e.target.value)}
          >
            <option value="en">EN - 🇺🇸</option>
            <option value="pt">PT - 🇧🇷</option>
          </select>
        </label>
      </li>
      <li>
        <a
          href="#cta"
          onClick={(e) => {
            trackEvent('book_click', 'Booking', 'Header Book Button', 'header');
            onIsOpen(e);
          }}
          className="nav-button"
        >
          {curLanguage === "en" ? "BOOK" : "AGENDAR"}
        </a>
      </li>
    </ul>
  );
}
